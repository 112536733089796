AFRAME.registerComponent("heat-pipe", {
    schema: {
      start: { type: "vec3" }, // Start point of the cable
      color: { default: "#00ff00" },
      flowSpeed: { default: 0.2 }, // Speed of flow
      thickness: { default: 0.05 }, // Thickness of the cable
      fraction: { default: 0.5 } // fraction variable for the "heat" (color) of the cable
    },
    init: function () {
      let start = this.data.start;
  
      // Define control points for the Catmull-Rom curve
      const xoff = -4;
      const xstart = 22;
      const xend = 4;
      const yoff = 0;
      const zoff = -0.5;
      const zlen = 3;
      let controlPoints1 = [];
      controlPoints1.push(new THREE.Vector3(start.x, start.y, start.z + zoff - 0.4));
      // Cusp
      for(let m = 0; m < 2; m = m + 1) {
        controlPoints1.push(new THREE.Vector3(start.x - xstart + 0.3, start.y, start.z + (xstart % 2) * zlen + zoff - 0.4));
      }
      controlPoints1.push(new THREE.Vector3(start.x - xstart + 0.3, start.y, start.z + (xstart % 2) * zlen + zoff));
      for(let k = xstart; xend <= k; k = k - 1) {
        if(k !== xstart) {
          controlPoints1.push(new THREE.Vector3(start.x - k - 0.3, start.y, start.z + (k % 2) * zlen + zoff));
        }
        if(k !== xend) {
          controlPoints1.push(new THREE.Vector3(start.x - k + 0.3, start.y, start.z + (k % 2) * zlen + zoff));
        }
      }
      controlPoints1.push(new THREE.Vector3(start.x - xend - 0.3, start.y, start.z + (xend % 2) * zlen + zoff));
      // Cusp
      for(let m = 0; m < 2; m = m + 1) {
        controlPoints1.push(new THREE.Vector3(start.x - xend - 0.3, start.y, start.z + zoff));
      }
      controlPoints1.push(new THREE.Vector3(start.x, start.y, start.z + zoff));

  
      // Create Catmull-Rom curve
      const curve = new THREE.CatmullRomCurve3(controlPoints1);
  
      // Split curve at the desired point
      const splitIndex = 3; // Index of the splitting point
      const curvePart1 = curve.clone();

      curvePart1.points = curve.points.slice(0, 3);
      const curvePart2  = curve.clone();
      curvePart2.points = controlPoints1.slice(2, splitIndex + 1);
  
      const curvePart3 = curve.clone();
      curvePart3.points = controlPoints1.slice(splitIndex);
  
      // Create geometry for both curve parts
      const radius = 0.05; // Adjust the radius to change thickness
      const curveGeometry1 = new THREE.TubeGeometry(curvePart1, 10, radius, 8, false);
      const curveGeometry2 = new THREE.TubeGeometry(curvePart2, 10, radius, 8, false);
      const curveGeometry3 = new THREE.TubeGeometry(curvePart3, 200, radius, 8, false);
    const vertexShaderRed = `
 varying vec2 vUv;
void main() {
  vUv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;
    const fragmentShaderRed = `
uniform float time;
uniform vec2 flowDirection;
uniform float flowSpeed;
uniform float stripeWidth; // Width of each stripe
varying vec2 vUv;
uniform float opacity; 
uniform float fraction;

void main() {
  // Calculate offset based on time, direction, and speed (unchanged)
  vec2 offset = vUv + flowDirection * time * flowSpeed;

  // Calculate stripe pattern based on UV coordinates and time
  float stripePosition = mod(offset.x + time * flowSpeed, stripeWidth * 2.0);
  float stripe = step(stripeWidth, stripePosition) - step(stripeWidth * 2.0, stripePosition);

  // Set red and other red color based on stripe pattern
  vec3 redColor = vec3(0.25 + fraction * 0.75, 0.25 - fraction * 0.25, 0.25 - fraction * 0.25); // red color
  vec3 otherRedColor = vec3(0.25 + fraction * 0.75, 0.25, 0.25 - fraction * 0.25); // other red color
  vec3 color = mix(redColor, otherRedColor, stripe); // Mix colors based on stripe pattern

  // Output the color with opacity
  gl_FragColor = vec4(color, opacity);
}
`;
    const vertexShaderBlue = `
 varying vec2 vUv;
void main() {
  vUv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;
    const fragmentShaderBlue = `
uniform float time;
uniform vec2 flowDirection;
uniform float flowSpeed;
uniform float stripeWidth; // Width of each stripe
varying vec2 vUv;
uniform float opacity; 
uniform float fraction;

void main() {
  // Calculate offset based on time, direction, and speed (unchanged)
  vec2 offset = vUv + flowDirection * time * flowSpeed;

  // Calculate stripe pattern based on UV coordinates and time
  float stripePosition = mod(offset.x + time * flowSpeed, stripeWidth * 2.0);
  float stripe = step(stripeWidth, stripePosition) - step(stripeWidth * 2.0, stripePosition);

  // Set blue and other blue colors based on stripe pattern
  vec3 blueColor = vec3(0.25 - fraction * 0.25, 0.25 - fraction * 0.25, 0.25 + fraction * 0.75); // blue color
  vec3 otherBlueColor = vec3(0.25 - fraction * 0.25, 0.25, 0.25 + fraction * 0.75); // other blue color
  vec3 color = mix(blueColor, otherBlueColor, stripe); // Mix colors based on stripe pattern

  // Output the color with opacity
  gl_FragColor = vec4(color, opacity);
}
`;
      // Create shader materials for red and blue sections
      const redMaterial = new THREE.ShaderMaterial({
        uniforms: {
          time: { value: 0 },
          flowDirection: { value: new THREE.Vector2(0, 1) },
          flowSpeed: { value: this.data.flowSpeed },
          stripeWidth: { value: 0.1 }, // Adjust stripe width as needed
          opacity: { value: 0.8 },
          fraction: { value: this.data.fraction },
        },
        vertexShader: vertexShaderRed,
        fragmentShader: fragmentShaderRed,
      });
  
      const blueMaterial = new THREE.ShaderMaterial({
        uniforms: {
          time: { value: 0 },
          flowDirection: { value: new THREE.Vector2(0, 1) },
          flowSpeed: { value: this.data.flowSpeed },
          stripeWidth: { value: 0.1 }, // Adjust stripe width as needed
          opacity: { value: 0.8 },
          fraction: { value: this.data.fraction },
        },
        vertexShader: vertexShaderBlue,
        fragmentShader: fragmentShaderBlue
      });
      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load('heat_pipe_transition_hot_cold.png');
      const transitionMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        opacity: 1.0
      });
  
      // Create meshes and add them to the scene
      const mesh1 = new THREE.Mesh(curveGeometry1, blueMaterial);
      const mesh2 = new THREE.Mesh(curveGeometry2, transitionMaterial);
      const mesh3 = new THREE.Mesh(curveGeometry3, redMaterial);
  
      this.el.setObject3D("mesh1", mesh1);
      this.el.setObject3D("mesh2", mesh2);
      this.el.setObject3D("mesh3", mesh3);
  
      // Start animating the electricity flow
      this.el.sceneEl.addEventListener("tick", this.tick.bind(this));
    },
  
    tick: function (time, deltaTime) {
      const dt = (deltaTime / 1000) * this.data.flowSpeed;
  
      // Update time uniforms for shader animation
      this.el.getObject3D("mesh1").material.uniforms.time.value += dt;
      this.el.getObject3D("mesh1").material.uniforms.fraction.value = this.data.fraction ;
      this.el.getObject3D("mesh2").material.opacity = 0.3 + this.data.fraction;
      this.el.getObject3D("mesh3").material.uniforms.time.value += dt;
      this.el.getObject3D("mesh3").material.uniforms.fraction.value = this.data.fraction;
    },
  });
  